// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-saffron-moroccan-chicken-js": () => import("./../../../src/pages/saffron-moroccan-chicken.js" /* webpackChunkName: "component---src-pages-saffron-moroccan-chicken-js" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */)
}

